import React, { useEffect, useState, useRef } from "react";
import gsap from "gsap";

import "./ex360v3.scss";

const images = [
  "/images/ext360fbdt/1-m.webp",
  "/images/ext360fbdt/2-m.webp",
  "/images/ext360fbdt/3-m.webp",
  "/images/ext360fbdt/4-m.webp",
  "/images/ext360fbdt/5-m.webp",
  "/images/ext360fbdt/6-m.webp",
  "/images/ext360fbdt/7-m.webp",
  "/images/ext360fbdt/8-m.webp",
  "/images/ext360fbdt/9-m.webp",
  "/images/ext360fbdt/10-m.webp",
  "/images/ext360fbdt/11-m.webp",
  "/images/ext360fbdt/12-m.webp",
  "/images/ext360fbdt/13-m.webp",
  "/images/ext360fbdt/14-m.webp",
  "/images/ext360fbdt/15-m.webp",
  "/images/ext360fbdt/16-m.webp",
  "/images/ext360fbdt/17-m.webp",
  "/images/ext360fbdt/18-m.webp",
  "/images/ext360fbdt/19-m.webp",
  "/images/ext360fbdt/20-m.webp",
  "/images/ext360fbdt/21-m.webp",
  "/images/ext360fbdt/22-m.webp",
  "/images/ext360fbdt/23-m.webp",
  "/images/ext360fbdt/24-m.webp",
  "/images/ext360fbdt/25-m.webp",
  "/images/ext360fbdt/26-m.webp",
  "/images/ext360fbdt/27-m.webp",
  "/images/ext360fbdt/28-m.webp",
  "/images/ext360fbdt/29-m.webp",
  "/images/ext360fbdt/30-m.webp",
  "/images/ext360fbdt/31-m.webp",
  "/images/ext360fbdt/32-m.webp",
  "/images/ext360fbdt/33-m.webp",
  "/images/ext360fbdt/34-m.webp",
  "/images/ext360fbdt/35-m.webp",
  "/images/ext360fbdt/36-m.webp",
];

const Ex360fbdtV3 = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [passkey, setPasskey] = useState("");
  const [formState, setFormState] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // State to track current image
  const [isDragging, setIsDragging] = useState(false); // Track drag state
  const [startX, setStartX] = useState(0); // Starting point of drag

  const moveThreshold = 25; // Adjust for sensitivity (higher = slower spin)

  // Handle the start of dragging (for both mouse and touch)
  const handleStart = (clientX) => {
    setIsDragging(true);
    setStartX(clientX);
  };

  // Handle mouse down
  const handleMouseDown = (e) => {
    handleStart(e.clientX);
  };

  // Handle touch start
  const handleTouchStart = (e) => {
    handleStart(e.touches[0].clientX); // `touches[0]` gets the first finger touch
  };

  // Handle the move (for both mouse and touch)
  const handleMove = (clientX) => {
    if (!isDragging) return;
    const movementX = clientX - startX;

    // Only change image if movement exceeds the threshold
    if (Math.abs(movementX) > moveThreshold) {
      let newIndex = currentImageIndex + Math.sign(movementX); // Increase or decrease the index
      if (newIndex >= images.length) newIndex = 0;
      if (newIndex < 0) newIndex = images.length - 1;
      setCurrentImageIndex(newIndex);
      setStartX(clientX); // Reset startX after each valid move
    }
  };

  // Handle mouse move
  const handleMouseMove = (e) => {
    handleMove(e.clientX);
  };

  // Handle touch move
  const handleTouchMove = (e) => {
    handleMove(e.touches[0].clientX);
  };

  // Stop dragging on mouse/touch end
  const stopDragging = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    window.addEventListener("mouseup", stopDragging);
    window.addEventListener("touchend", stopDragging);
    return () => {
      window.removeEventListener("mouseup", stopDragging);
      window.removeEventListener("touchend", stopDragging);
    };
  }, []);

  useEffect(() => {
    function setContainerHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    // Call the function when the window is resized
    window.addEventListener("resize", setContainerHeight);

    // Initial call to set the container height
    setContainerHeight();
  }, []);

  // Handles the mouse down event
  // const handleMouseDown = (e) => {
  //   setIsDragging(true);
  //   setStartX(e.clientX);
  // };

  // // Handles the mouse move event
  // const handleMouseMove = (e) => {
  //   if (!isDragging) return;
  //   const movementX = e.clientX - startX;

  //   const moveThreshold = 25;

  //   if (Math.abs(movementX) > moveThreshold) {
  //     let newIndex = currentImageIndex + Math.sign(movementX);
  //     if (newIndex >= images.length) newIndex = 0;
  //     if (newIndex < 0) newIndex = images.length - 1;
  //     setCurrentImageIndex(newIndex);
  //     setStartX(e.clientX);
  //   }
  // };

  // const stopDragging = () => {
  //   setIsDragging(false);
  // };

  // useEffect(() => {
  //   window.addEventListener("mouseup", stopDragging);
  //   return () => window.removeEventListener("mouseup", stopDragging);
  // }, []);

  const handlePasswordSubmit = () => {
    if (passkey === "test123") {
      sessionStorage.setItem("authenticated", "true");
      setFormState("success");

      setTimeout(() => {
        setFormState(""); // Clear the form state after 2 seconds
      }, 2000);

      // Fade out the modal
      gsap.to("#modal", {
        opacity: 0,
        duration: 2,
        delay: 1,
        onComplete: () => {
          setIsAuthenticated(true);
        },
      });
    } else {
      setFormState("error");

      setTimeout(() => {
        setFormState(""); // Clear the form state after 2 seconds
      }, 2000);
    }
  };

  useEffect(() => {
    const authStatus = sessionStorage.getItem("authenticated");
    if (authStatus === "true") {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <section className="exfbdt">
      <div className="container">
        <div
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onTouchStart={handleTouchStart} // Touch start for mobile
          onTouchMove={handleTouchMove} // Touch move for mobile
          onMouseUp={stopDragging}
          onMouseLeave={stopDragging}
          className="exfbdtSeq"
          style={{
            cursor: isDragging ? "grabbing" : "pointer",
            userSelect: "none",
          }}
        >
          <img
            src={images[currentImageIndex]} // Display current image
            alt={`Spin view ${currentImageIndex}`}
            style={{
              maxHeight: "100vh",
              maxWidth: "100%",
              userSelect: "none", // Prevent text/image selection while dragging
              pointerEvents: "none", // Disable image pointer events
            }}
          />
        </div>
      </div>
      <div className={isAuthenticated ? "modal none" : "modal"} id="modal">
        <div className="modalContent">
          <div className={`form ${formState}`}>
            {/* <h3>Please enter the password</h3> */}
            <input
              type="password"
              value={passkey}
              onChange={(e) => setPasskey(e.target.value)}
              placeholder="Please enter the passkey"
            />
            <button
              onClick={handlePasswordSubmit}
              disabled={passkey.length > 2 ? false : true}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ex360fbdtV3;
