export const sidebar = [
  {
    title: "Lens",
    types: ["18mm", "20mm", "24mm", "28mm", "35mm", "50mm", "85mm", "110mm"],
  },
  {
    title: "Lighting",
    types: [
      "Studio 01",
      "Studio 02",
      "Studio 03",
      "Outdoor 01",
      "Outdoor 02",
      "Outdoor 03",
    ],
  },
];

export const allSidebar = [
  {
    title: "Lens",
    types: ["18mm", "20mm", "24mm", "28mm", "35mm", "50mm", "85mm", "110mm"],
  },
  {
    title: "Body Colour",
    types: [
      "Abyss black pearl",
      "Atlas white",
      "Fiery red pearl",
      "Starry nigh",
      "Frost blue metallic",
      "Frost blue matte",
      "Titan grey matte",
      "Robust emerald matte",
      "Atlas white + black roof",
      "Frost blue metallic + black roof",
    ],
  },
  {
    title: "Lighting",
    types: [
      "Studio 01",
      "Studio 02",
      "Studio 03",
      "Outdoor 01",
      "Outdoor 02",
      "Outdoor 03",
    ],
  },
];

