import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/home";
import Login from "../pages/login";
import Ex360fbdt from "../pages/ex360fbdt";
import Ex360fbdtV2 from "../pages/ex360fbdt-v2";
import Ex360fbdtV3 from "../pages/ex360fbdt-v3";


const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/ev" element={<Home />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/ex360fbdt" element={<Ex360fbdt />} />
        <Route exact path="/ex360fbdt-v2" element={<Ex360fbdtV2 />} />
        <Route exact path="/ex360fbdt-v3" element={<Ex360fbdtV3 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
