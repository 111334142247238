import React, { useEffect } from "react";

const ImageSequence = () => {
  useEffect(() => {
    window.CI360.init();
  }, []);

  return (
    <div
      //   style="margin-bottom: 80px"
      class="cloudimage-360"
      data-folder="https://scaleflex.cloudimg.io/v7/demo/suv-orange-car-360/"
      data-filename-x="orange-{index}.jpg"
      data-amount-x="36"
      data-magnifier="3"
      data-spin-reverse
      data-responsive="scaleflex"
    ></div>
  );
};

export default ImageSequence;
