import { BrandLogo, GridSize } from "../../svgIcons";
import { sidebar } from "../../data/sidebar";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  fetchImages,
  staggeredCards,
  applyModeAnimations,
  handleLens,
  handleBodycolor,
  handleLighting,
  handleMessage,
  handleDebug,
  // handleTerminateSession,
} from "../../utils/homeUtils";
import gsap from "gsap";

import "./home.scss";

function Home() {
  const navigate = useNavigate();
  const cardContainer = useRef(null);
  const [item, setItem] = useState();
  const [selectedCards, setSelectedCards] = useState([]);
  const [mode, setMode] = useState("interactive");
  const [selectedTypes, setSelectedTypes] = useState(["35mm", "Studio 01"]);
  const [activeTypeIndices, setActiveTypeIndices] = useState([4, 0]);
  const [assetCards, setAssetCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [removeLoader, setRemoveLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [isCaptureClicked, setIsCaptureClicked] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageStart, setPageStart] = useState(1);
  const [pageEnd, setPageEnd] = useState(10);
  const [lightAngle, setlightAngle] = useState(180);
  const [lightIntense, setLightIntense] = useState(0.85);
  const [cardSize, setCardSize] = useState(8);

  // Admin authorization check
  useEffect(() => {
    const adminAuth = sessionStorage.getItem("admin");
    setIsAdmin(adminAuth === "true");
  }, []);

  // Fetch images, compress them and store in state
  useEffect(() => {
    const fetchAndSetImages = async () => {
      const imageUrls = await fetchImages();
      setAssetCards(imageUrls);
    };

    fetchAndSetImages();
  }, []);

  // Batch stagger loading for asset cards
  useEffect(() => {
    if (mode === "capture") {
      staggeredCards(cardContainer, ".assetcard");
    }
  }, [mode, currentPage, cardSize]);

  useEffect(() => {
    const handleMessageEvent = (event) => handleMessage(event, setMessage);
    window.addEventListener("message", handleMessageEvent);
    return () => window.removeEventListener("message", handleMessageEvent);
  }, []);

  useEffect(() => {
    applyModeAnimations(mode);
  }, [mode]);

  // loader with a delay timer
  useEffect(() => {
    if (message === "loadingComplete") {
      setLoading(false);
    }

    if (!loading) {
      setTimeout(() => {
        setRemoveLoader(true);
      }, 500);
    }

    if (!loading) {
      gsap.fromTo(
        "#loader",
        0.5,
        {
          opacity: 1,
          ease: "power2.inOut",
        },
        {
          opacity: 0,
          ease: "power2.inOut",
        }
      );
    }
  }, [loading, message]);
  

  // this is for the selection of cards in captures page
  const handleCardClick = (index, event) => {
    if (event.shiftKey) {
      // Shift + Click
      if (selectedCards.includes(index)) {
        setSelectedCards(selectedCards.filter((i) => i !== index));
      } else {
        setSelectedCards([...selectedCards, index]);
      }
    } else {
      if (selectedCards.includes(index)) {
        setSelectedCards(selectedCards.filter((i) => i !== index));
      } else {
        setSelectedCards([index]);
      }
    }
  };

  // onclick i and c change modes accordingly
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setSelectedCards([]);
    } else if (event.key === "i") {
      setMode("interactive");
    } else if (event.key === "c") {
      setMode("capture");
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // useeffect to authenticate user and navigate to home page
  useEffect(() => {
    gsap.fromTo(
      "#main",
      0.5,
      {
        opacity: 0,
        ease: "power2.inOut",
      },
      { opacity: 1, ease: "power2.inOut" }
    );

    const authStatus = sessionStorage.getItem("authenticated");
    if (authStatus === "true") {
      navigate("/ev");
    } else {
      navigate("/login");
    }
  }, [navigate]);

  // to select the type in the sidebar like lens, bodycolor etc.
  const handleTypeClick = (sectionIndex, type, typeIndex) => {
    const newSelectedTypes = [...selectedTypes];
    newSelectedTypes[sectionIndex] = type; // Update the selected type for the specific section
    setSelectedTypes(newSelectedTypes);

    const newActiveTypeIndices = [...activeTypeIndices];
    newActiveTypeIndices[sectionIndex] = typeIndex; // Set the active class for the specific section
    setActiveTypeIndices(newActiveTypeIndices);

    if (sectionIndex === 0) {
      if (type.length === 4) {
        handleLens(type.slice(0, 2)); // Handle lens selection
      } else if (type.length === 5) {
        handleLens(type.slice(0, 3)); // Handle lens selection
      }
    } else if (sectionIndex === 1) {
      handleLighting(typeIndex + 1); // Handle lighting selection
    } else if (sectionIndex === 2) {
      handleBodycolor(typeIndex + 1); // Handle body color selection
    }
  };

  // capture the unreal app
  function handleCapture() {
    setIsCaptureClicked(true);
    setTimeout(() => {
      setIsCaptureClicked(false);
    }, 1000);

    const obj = { capture: 1 };
    document.getElementById("iframe_1").contentWindow.postMessage(obj, "*");

    setTimeout(async () => {
      const updatedImageUrls = await fetchImages();
      setAssetCards(updatedImageUrls);
    }, 10000);
  }

  // reset all the types selected
  function handleReset() {
    setSelectedTypes(["35mm", "Studio 01"]);

    setActiveTypeIndices([4, 0]);
    setItem();
    setlightAngle(180);
    setLightIntense(0.85);

    const resetObj = { resetAll: 1 };
    document
      .getElementById("iframe_1")
      .contentWindow.postMessage(resetObj, "*");
  }

  // download assets function
  const handleDownload = async () => {
    if (!selectedCards.length) return;
    const itemsPerPage = cardSize; // Number of cards per page
    const offset = (currentPage - 1) * itemsPerPage; // Calculate the offset based on the current page

    for (const cardIndex of selectedCards) {
      const adjustedIndex = cardIndex + offset; // Adjust the cardIndex to account for pagination
      const imageUrl = assetCards[adjustedIndex];
      const imgixurl = "https://anglehunters.imgix.net/";

      try {
        // Construct the full S3 image URL
        const fullImageUrl = imgixurl + imageUrl;

        // Fetch the image data to create a Blob
        const response = await fetch(fullImageUrl);
        const blob = await response.blob();

        // Create a new anchor tag to download the image
        const link = document.createElement("a");
        const imgFileName = imageUrl.split(".png")[0]; // Get the part before the query string

        // Create an object URL from the blob
        const objectUrl = URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = imgFileName; // Set the filename for download

        // Trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up by removing the anchor tag and revoking the object URL
        document.body.removeChild(link);
        URL.revokeObjectURL(objectUrl);
      } catch (error) {
        console.error("Error downloading image:", error);
      }
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      setMessage(event.data);
    };

    window.addEventListener("message", handleMessage);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleCardSizes = () => {
    if (cardSize === 8) {
      setCardSize(15);
    } else {
      setCardSize(8);
    }
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(assetCards.length / cardSize);

  // Get the current cards to display based on the current page
  const currentCards = assetCards.slice(
    (currentPage - 1) * cardSize,
    currentPage * cardSize
  );

  // Handle next and previous page clicks
  const handleNextPage = () => {
    if (selectedCards.length) {
      setSelectedCards([]);
    }
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (selectedCards.length) {
      setSelectedCards([]);
    }
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Handle shifting the displayed page numbers
  const handleNextPageSet = () => {
    if (pageEnd < totalPages) {
      setPageStart(pageStart + 10);
      setPageEnd(Math.min(pageEnd + 10, totalPages));
    }
  };

  const handlePrevPageSet = () => {
    if (pageStart > 1) {
      setPageStart(pageStart - 10);
      setPageEnd(pageStart - 1);
    }
  };

  // Generate the array of page numbers to display
  const pageNumbers = [];
  for (let i = pageStart; i <= Math.min(pageEnd, totalPages); i++) {
    pageNumbers.push(i);
  }
  

  const handleLightAngle = (e) => {
    setlightAngle(e.target.value);
    const obj = { lightAngle: e.target.value };
    document.getElementById("iframe_1").contentWindow.postMessage(obj, "*");
  };
  const handleLightIntensity = (e) => {
    setLightIntense(e.target.value);
    const obj = { lightIntensity: e.target.value };
    document.getElementById("iframe_1").contentWindow.postMessage(obj, "*");
  };

  return (
    <section className="unreal" id="main">
      {/* <h4></h4> */}
      <div className="container">
        {!removeLoader ? (
          <div className="unrealLoader" id="loader">
            <div className="unrealLoaderBody">
              <div className="unrealLoaderSpin"></div>
            </div>
            <div className="unrealFooter">
              <div className="unrealLogo">
                <BrandLogo />
                <p>Angle hunting</p>
              </div>
              <div className="unrealCta">
                <button>LOADING</button>
              </div>
              <div className="unrealCopy"></div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="unrealContent">
          <div className="unrealSidebar" id="unreadSidebar">
            <div className="unrealList">
              <div className="unrealSidebarReset" onClick={handleReset}>
                <span>Reset</span>
              </div>
              {sidebar.map((val, i) => {
                return (
                  <div className="unrealAccordion" key={i}>
                    <div
                      className="unrealQtn"
                      style={{
                        borderRadius: item === i ? "6px 6px 0 0" : "6px",
                      }}
                      onClick={() => {
                        if (item === i) {
                          setItem();
                        } else {
                          setItem(i);
                        }
                      }}
                    >
                      {/* handle individual reset can be enabled */}
                      {/* {selectedTypes[i] === null ? (
                            ""
                          ) : (
                            <span
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent triggering the parent click event
                                handleTypeReset(i);
                              }}
                            >
                              {" "}
                              <img src={Reset} alt="" />
                            </span>
                          )} */}

                      <h2>{val.title}</h2>
                      <h3>{selectedTypes[i]} &nbsp;</h3>
                    </div>
                    <div
                      className="unrealOpt"
                      style={{ display: item === i ? "block" : "none" }}
                    >
                      {val.types.map((type, index) => {
                        return (
                          <p
                            key={index}
                            className={
                              activeTypeIndices[i] === index ? "activeOpt" : ""
                            }
                            onClick={() => handleTypeClick(i, type, index)}
                          >
                            {type}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              {isAdmin ? (
                <div className="unrealSidebarReset" onClick={handleDebug}>
                  <span>Debug</span>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="unrealLogo">
              <BrandLogo />
              <p>Angle hunting</p>
            </div>
          </div>

          <div className="unrealBody" ref={cardContainer}>
            <div
              className="unrealAsset"
              id="pixelstream"
              style={{ opacity: mode === "interactive" ? 1 : 0 }}
            >
              <div className="unrealAssetWrap">
                <iframe
                  style={{ visibility: "visible", border: "none" }}
                  id="iframe_1"
                  src="https://share.streampixel.io/share-055864fc-aa44-4bd0-bd58-9428ce171d8a"
                  width="100%"
                  height="100%"
                  allowFullScreen={false}
                  allow="xr-spatial-tracking"
                  title="host"
                ></iframe>
              </div>
            </div>

            <div
              className={
                mode === "interactive"
                  ? "unrealCaptures"
                  : "unrealCaptures unrealCapturesActive"
              }
              id="unrealcaptures"
            >
              <div className="unrealCapturesBody">
                {currentCards.length ? (
                  <>
                    <div
                      className={
                        cardSize === 8
                          ? "unrealAssetContent unrealAssetContentBig"
                          : "unrealAssetContent"
                      }
                    >
                      {currentCards?.map((img, i) => {
                        return (
                          <div
                            key={i}
                            className={`assetcard ${
                              selectedCards.includes(i) ? "assetcardActive" : ""
                            }`}
                            onClick={(event) => handleCardClick(i, event)}
                          >
                            <div>
                              <img
                                src={`https://anglehunters.imgix.net/${img}?q=50&auto=format&fm=webp&w=600`}
                                alt="asset"
                                loading="lazy"
                              />
                            </div>
                            <h2>{img.split(".png")}</h2>
                            {/* <p>Lens: 35mm</p>
                      <p>Colour: Aurora black</p> */}
                          </div>
                        );
                      })}
                      <div className="assetcardDummy"></div>
                      <div className="assetcardDummy"></div>
                      <div className="assetcardDummy"></div>
                      <div className="assetcardDummy"></div>
                    </div>
                    <div className="unrealPageFooter">
                      {totalPages > 1 ? (
                        <div className="unrealPagination">
                          <p
                            className={`prev ${
                              pageStart === 1 ? "disabled" : ""
                            }`}
                            onClick={handlePrevPageSet}
                          >
                            {"<<"}
                          </p>
                          <p
                            className={`prev ${
                              currentPage === 1 ? "disabled" : ""
                            }`}
                            onClick={handlePrevPage}
                          >
                            {"<"}
                          </p>
                          {pageNumbers.map((pageNumber) => (
                            <p
                              key={pageNumber}
                              className={
                                currentPage === pageNumber ? "active" : ""
                              }
                              onClick={() => {
                                setCurrentPage(pageNumber);
                                if (selectedCards.length) {
                                  setSelectedCards([]);
                                }
                              }}
                            >
                              {pageNumber}
                            </p>
                          ))}
                          <p
                            className={`next ${
                              currentPage === totalPages ? "disabled" : ""
                            }`}
                            onClick={handleNextPage}
                          >
                            {">"}
                          </p>
                          <p
                            className={`next ${
                              pageEnd >= totalPages ? "disabled" : ""
                            }`}
                            onClick={handleNextPageSet}
                          >
                            {">>"}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      {(currentPage !== totalPages ||
                        (currentPage === totalPages && cardSize === 15)) && (
                        <div className="unrealGridLayout">
                          <p
                            onClick={handleCardSizes}
                            className={cardSize === 8 ? "" : "activeGrid"}
                          >
                            <GridSize />
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="unrealLoaderSmall">
                    <div className="unrealLoaderSmallBody">
                      <div className="unrealLoaderSpin"></div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="unrealFooter">
              <div className="unrealInfoBtn">
                <p
                  className={mode === "interactive" ? "active" : ""}
                  onClick={() => setMode("interactive")}
                >
                  (i) Interactive
                </p>
                <p
                  className={mode === "capture" ? "active" : ""}
                  onClick={() => setMode("capture")}
                >
                  (c) Captures: &nbsp;&nbsp;
                  {assetCards.length ? assetCards.length : ""}
                </p>
              </div>
              <div className="unrealCta">
                {mode === "interactive" ? (
                  <button
                    onClick={handleCapture}
                    className={isCaptureClicked ? "clicked" : ""}
                  >
                    CAPTURE
                  </button>
                ) : (
                  <button
                    disabled={selectedCards.length ? false : true}
                    onClick={handleDownload}
                  >
                    DOWNLOAD{" "}
                    {selectedCards.length ? `(${selectedCards.length})` : ""}
                  </button>
                )}
              </div>
              <div className="unrealCopy">
                {mode === "interactive" ? (
                  <div className="unrealLighting">
                    <div className="unrealRange">
                      <p>Rotate Light</p>
                      {/* <div className="unrealRangeSlide">
                        <span></span>
                      </div> */}
                      <input
                        type="range"
                        step="0.1"
                        min={0}
                        max={360}
                        value={lightAngle}
                        onChange={handleLightAngle}
                      />
                    </div>
                    <div className="unrealRange">
                      <p>Exposure</p>
                      {/* <div className="unrealRangeSlide">
                        <span></span>
                      </div> */}
                      <input
                        type="range"
                        step="0.01"
                        min={0.2}
                        max={1.5}
                        value={lightIntense}
                        onChange={handleLightIntensity}
                      />
                    </div>
                  </div>
                ) : (
                  <label>Shift + click to select mulitple</label>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="unsopported">
        <p>
          Please visit us again on a desktop. <br />
          Thank you
        </p>
      </div>
    </section>
  );
}

export default Home;
