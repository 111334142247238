import React, { useEffect, useState, useRef } from "react";
import ThreeSixty from "react-360-view";
import gsap from "gsap";

import "./ex360.scss";

const Ex360fbdt = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [passkey, setPasskey] = useState("");
  const [formState, setFormState] = useState("");

  const handlePasswordSubmit = () => {
    if (passkey === "test123") {
      sessionStorage.setItem("authenticated", "true");
      setFormState("success");

      setTimeout(() => {
        setFormState(""); // Clear the form state after 2 seconds
      }, 2000);

      // Fade out the modal
      gsap.to("#modal", {
        opacity: 0,
        duration: 2,
        delay: 1,
        onComplete: () => {
          setIsAuthenticated(true);
        },
      });
    } else {
      setFormState("error");

      setTimeout(() => {
        setFormState(""); // Clear the form state after 2 seconds
      }, 2000);
    }
  };

  useEffect(() => {
    const authStatus = sessionStorage.getItem("authenticated");
    if (authStatus === "true") {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <section className="exfbdt">
      <div className="container">
        {/* <ThreeSixty
          amount={36}
          imagePath="/images/ext360fbdt/"
          fileName="{index}-m.webp"
        /> */}
        <iframe
          src="https://venkatasaitonic.sirv.com/Spins/ext360fbdt/ext360fbdt.spin"
          width="100%"
          height="100%"
          frameborder="0"
          allowfullscreen={false}
          autospin={0}
          reverse={1}
        ></iframe>
      </div>
      <div className={isAuthenticated ? "modal none" : "modal"} id="modal">
        <div className="modalContent">
          <div className={`form ${formState}`}>
            {/* <h3>Please enter the password</h3> */}
            <input
              type="password"
              value={passkey}
              onChange={(e) => setPasskey(e.target.value)}
              placeholder="Please enter the passkey"
            />
            <button
              onClick={handlePasswordSubmit}
              disabled={passkey.length > 2 ? false : true}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ex360fbdt;
